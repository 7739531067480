<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <aom-skeleton-loader v-if="isLoading" />
        <b-row v-else>
          <b-col class="d-flex justify-content-end align-items-start">
            <action-button
              @click="showBulkExportModal"
              variant="outline-primary"
              class="btn-icon mr-1">
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="mr-50"
              /> 
              Export
            </action-button>
            
            <action-button
              v-if="type === groupType.FILTER && !isProgramTypeTraining"
              class="add-custom-btn mr-50 mb-1"
              variant="primary"
              :to="{ name: 'champion-edit-filter-group', params: { id: defaultProgramId, groupId: this.$route.params.groupId } }"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Edit</span>
            </action-button>
            <action-button
              v-if="type === groupType.CUSTOM"
              class="add-custom-btn mr-50"
              variant="primary"
              @click="openCustomGroupSideBar"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Edit</span>
            </action-button>
          </b-col>
          <b-col sm="12">
            <h3 class="ml-2">
              {{ groupName }}
            </h3>
          </b-col>
        </b-row>
        <filter-group-list
          ref="filterGroupList"
          :group-name="groupName"
          :group-type-id="type"
        />
        <edit-custom-group
          :open="openEditGroup"
          :group-name="groupName"
          @updated="groupUpdated"
        />
      </b-card>
    </b-col>
    <b-modal
      v-model="showExportModal"
      :title="'Export'"
      centered
    >
    <div class="d-flex justify-content-center align-items-center pt-4 pb-4">
        <feather-icon
          v-b-tooltip.hover.top="'Enabling matches to be exported will cause duplicate rows to be created.'"
          icon="InfoIcon"
          size="16"

        /> 

        <b-form-checkbox
          v-model="exportWithMatches"
          value="accepted"
          unchecked-value=""
          class="ml-2"
        >
        Export matches
        </b-form-checkbox>  
    </div>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div class="d-flex w-100 justify-content-center">
          <b-button
            variant="primary"
            centered
           class="mr-2"
            @click="onDownloadParticipantList('csv')"
          >
            <span >Export as CSV</span>
          </b-button>

          <b-button
            variant="primary"
            right
            @click="onDownloadParticipantList('xlsx')"
          >
            <span>Export as XLSX</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-row>
  
</template>
    
<script>
import { BRow, BCol, BButton, BCard, BDropdown, BFormCheckbox, BDropdownItem, VBTooltip } from "bootstrap-vue";
import FilterGroupList from "./FilterGroupList.vue";
import { mapGetters } from 'vuex';
import AomSkeletonLoader from "@/@aom-core/AomSkeletonLoader.vue";
import groupsService from "@/services/groupsService";
import { groupType, programTypes } from '@models';
import EditCustomGroup from "./EditCustomGroup.vue";
import { BE_API_URL } from "@/constants/app";
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  name: 'FilterAndCustomGroup',
  components: {
    ActionButton,
    BRow,
    BCol,
    BButton,
    BCard,
    BButton,
    FilterGroupList,
    AomSkeletonLoader,
    EditCustomGroup,
    BDropdown,
    BFormCheckbox,
    BDropdownItem
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      isLoading: false,
      groupName: '',
      type: undefined,
      groupType,
      openEditGroup: false,
      showExportModal: false,
      exportWithMatches: false,
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
  created () {
    this.fetchGroup();
  },
  methods: {
    openCustomGroupSideBar() {
      this.openEditGroup = true;
    },
    groupUpdated() {
      this.openEditGroup = false;
      this.fetchGroup();
      this.$refs.filterGroupList.loadItems();
    },
    async fetchGroup() {
      try {
        this.isLoading = true;
        const programId = this.defaultProgramId;
        const groupId = this.$route.params.groupId;
        const response = await groupsService.getGroupById(programId, groupId);
        const {name, type_id} = response.data;
        this.groupName = name;
        // eslint-disable-next-line camelcase
        this.type = type_id;
      } catch (e) {
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    onDownloadParticipantList(type) {
       const groupId = this.$route.params.groupId;
       const dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/groups/${groupId}/download/${type}?with_participant_candidates=1&with_participant_matches=${this.exportWithMatches ? 1 : 0}`;
       window.document.location = dowloadLink;
       this.hideBulkExportModal();
    },
    showBulkExportModal() {
      this.showExportModal = true;
    },
    hideBulkExportModal() {
      this.showExportModal = false;
    },
  }
};
</script>
    
<style lang="scss">
  #add-participant___title__ {
    font-size: 18px;
    font-weight: 500;
    flex:2;
  }
  
  .b-sidebar > .b-sidebar-header {
    display: flex;
    flex-direction: row-reverse;
  }
  $sidebar-header-text-color: #808080;
      .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;

      #edit-participant___title__ {
          font-size: .8em;
          flex: 2;
      }
  }
</style>